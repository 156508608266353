import get from 'lodash.get'
import { graphql, useStaticQuery } from 'gatsby'

export function convertHolidaysToDateObjs (d) {
  return d.map(h => {
    // Because why would Safari know how to do dates like everyone else?
    const [d] = h.start.split('T')
    const [yyyy, mm, dd] = d.split('-')
    return new Date(yyyy, parseInt(mm, 10) - 1, dd)
  })
}

export function getFromMap (map, key, defaultValue = null) {
  if (Object.hasOwnProperty.call(map, key)) {
    return map[key]
  }

  if (defaultValue !== null) {
    return defaultValue
  }

  throw new Error(`Invalid key in map: ${key}`)
}

export function isServer () {
  return !canUseDom()
}

export function canUseDom () {
  return (typeof window !== 'undefined' && window.document && window.document.createElement)
}

export function canUseGA () {
  return (typeof window !== 'undefined' && window.ga)
}

export function canUseInvoca () {
  return (typeof window !== 'undefined' && window.Invoca)
}

export function randomHash () {
  return Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7)
}

export function cdn (assetSrc) {
  if (!assetSrc) return ''
  // Ignore URLs that already specify a schema
  if (assetSrc.startsWith('http')) {
    return assetSrc
  }
  return assetSrc.startsWith('/') ? `${process.env.GATSBY_IMAGE_PREFIX}${assetSrc}` : `${process.env.GATSBY_IMAGE_PREFIX}/${assetSrc}`
}

// From: https://codeburst.io/throttling-and-debouncing-in-javascript-646d076d0a44
export function throttle (delay, fn) {
  let lastCall = 0
  return function (...args) {
    const now = (new Date()).getTime()
    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return fn(...args)
  }
}

// Helps prevent undefined from sneaking into the class names.
// Should be used anytime class names have the potential to be undefined such as props.
export function safeClassName (...classNames) {
  return classNames.reduce((accumulator, className) => {
    if (!className) return accumulator
    return accumulator ? `${accumulator} ${className}` : className
  }, '')
}

export function insertAfter (newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
}

export function removeSelf (node) {
  node.parentNode.removeChild(node)
}

export function prepend (val, expectedLength, fillString) {
  return String(val).padStart(expectedLength, fillString)
}
// from https://gomakethings.com/how-to-get-the-value-of-a-querystring-with-native-javascript/
export function getQueryString (field, url = undefined) {
  const reg = new RegExp(`[?&]${field}=([^&#]*)`, 'i')
  const result = reg.exec(url || window.location.href)
  return result ? result[1] : null
}

export function splitFormStepInputAttributes (attributes) {
  let formStepClass = ''
  const outerAttributes = {}
  for (const key in attributes) {
    if (key === 'data-js-outer-form-step' && attributes[key] > 0) formStepClass = 'hidden'
    if (key.includes('-outer-')) {
      outerAttributes[key] = attributes[key]
      delete attributes[key]
    }
  }
  return {
    outerAttributes,
    innerAttributes: attributes,
    formStepClass
  }
}

/**
 * Sort helper for sorting ascending
 * @param {string|number} a
 * @param {string|number} b
 */
const sortASC = (a, b) => {
  if (a < b) {
    return -1
  }

  if (a > b) {
    return 1
  }

  return 0
}

/**
 * Sort helper for sorting descending
 * @param {string|number} a
 * @param {string|number} b
 */
const sortDESC = (a, b) => {
  if (a < b) {
    return 1
  }

  if (a > b) {
    return -1
  }

  return 0
}

/**
 * Sort array with ascending or descending order.
 *
 * @param {array} array
 * @param {string} path
 * @param {'ASC'|'DESC'} order
 */
export const sort = (array, path, order = 'ASC') => {
  return array.sort((a, b) => {
    let leftVal = get(a, path)
    let rightVal = get(b, path)

    if (!leftVal || !rightVal) {
      throw Error(`Can't compare ${leftVal} with ${rightVal}`)
    }

    if (isNaN(leftVal)) {
      leftVal = String(leftVal).toLowerCase()
    }

    if (isNaN(rightVal)) {
      rightVal = String(rightVal).toLowerCase()
    }

    const comparators = {
      ASC: sortASC,
      DESC: sortDESC
    }

    return comparators[order.toUpperCase()](leftVal, rightVal)
  })
}

export function toEstTime (dateString) {
  try {
    const dateObj = new Date(dateString)
    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric'
    }).format(dateObj)
  } catch (error) {
    console.error(`toEstTime: Could not parse dateString ${dateString}`)
    return 'N/A'
  }
}

export function formatDateForStructuredData (time) {
  if (time) return time.split('T')[0]
}

export function formatDate (dateStr) {
  const [year, month, day] = dateStr.split('-')
  return {
    year,
    month,
    day
  }
}

export function convertHour (hour) {
  return hour > 12 ? hour - 12 : hour
}

export function timeStamp () {
  // Get local time as ISO string with offset at the end
  const now = new Date()
  const tzo = -now.getTimezoneOffset()
  const dif = tzo >= 0 ? '+' : '-'
  const pad = function (num) {
    const norm = Math.abs(Math.floor(num))
    return (norm < 10 ? '0' : '') + norm
  }
  /* eslint-disable operator-linebreak */
  return now.getFullYear()
    + '-' + pad(now.getMonth() + 1)
    + '-' + pad(now.getDate())
    + 'T' + pad(now.getHours())
    + ':' + pad(now.getMinutes())
    + ':' + pad(now.getSeconds())
    + '.' + pad(now.getMilliseconds())
    + dif + pad(tzo / 60)
    + ':' + pad(tzo % 60)
}

export function getTrustPilotLocalBusinessSchemaData () {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { trustPilotPublicBusinessUnit } = useStaticQuery(graphql`
  query trustPilot {
    trustPilotPublicBusinessUnit {
      score {
        trustScore
      }
      numberOfReviews {
        total
      }
    }
  }
`)
  return {
    totalRating: trustPilotPublicBusinessUnit.score.trustScore || 4.2,
    totalReviews: trustPilotPublicBusinessUnit.numberOfReviews.total || 5869
  }
}

export const mapIntToTrustpilotIcon = (num) => {
  if (typeof num !== 'number') return ''
  if (num >= 3.3 && num <= 3.7) {
    return 'trustpilot-rating-3-half-stars'
  } else if (num >= 3.8 && num <= 4.2) {
    return 'trustpilot-rating-4-stars'
  } else if (num >= 4.3 && num <= 4.7) {
    return 'trustpilot-rating-4-half-stars'
  } else if (num >= 4.8) {
    return 'trustpilot-rating-5-stars'
  }
}

export const getTrustPilotRatingName = (score) => {
  if (typeof score !== 'number') return ''
  if (score >= 3.3 && score <= 3.7) {
    return 'Average'
  } else if (score >= 3.8 && score <= 4.2) {
    return 'Great'
  } else if (score >= 4.3) {
    return 'Excellent'
  }
}

/**
 * Get they key from an object through its value
 *
 * @param {string} value
 * @param {object} object
 */
export const getKeyByValue = (value, object) => {
  return Object.keys(object).find(key => object[key] === value)
}

export const isCallOrEmailLink = (url) => {
  return url.startsWith('tel:') || url.startsWith('mailto:')
}

export const isRelativeUrl = (url) => {
  return url.startsWith('/')
}

export const isExternalUrl = (url) => {
  if (url === '' || url.startsWith('#')) return false
  const absoluteUrl = isRelativeUrl(url) ? process.env.GATSBY_PRODUCTION_SITE_URL + url : url
  return absoluteUrl.indexOf('budgetdumpster.com') === -1 && !isCallOrEmailLink(absoluteUrl)
}

/**
 * Conditionally wrap elements with another
 * Wrap should be formatted like: wrap={children => <a href="/">{children}</a>}
 * Copied from: https://gist.github.com/kitze/23d82bb9eb0baabfd03a6a720b1d637f
 *
 * @param {boolean} condition
 * @param {function} wrap
 * @param {element} children
 */
export const ConditionalWrap = ({ condition, wrap, children }) => condition ? wrap(children) : children
